import React, { useContext, useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaShoppingCart, FaEdit } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { CartContext } from "../../contexts/CartContext";
import { ProductContext } from "../../contexts/ProductContext";

const Header = () => {
  const { cartItems } = useContext(CartContext);
  const {
    setIsZipper,
    setIsLockable,
    setSelectedFilters,
    setCategoryFilter,
    setPriceRange,
    setSizeFilter,
    setMaterialFilter,
  } = useContext(ProductContext); // Access product filter setters

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const sidebarRef = useRef(null);

  const totalCartItems = cartItems.length;

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarToggle(false);
    }
  };

  const clearFilters = () => {
    // Reset the state variables
    setIsZipper("");
    setIsLockable("");
    setSelectedFilters([]);
    setCategoryFilter([]);
    setPriceRange([0, 1000]);
    setSizeFilter([]);
    setMaterialFilter("");

    // Clear localStorage
    localStorage.removeItem("isZipper");
    localStorage.removeItem("isLockable");
    localStorage.removeItem("selectedFilters");
    localStorage.removeItem("categoryFilter");
    localStorage.removeItem("sizeFilter");
    localStorage.removeItem("materialFilter");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={isFixed ? "fixed-header" : ""}>
        {/* <header> */}
        <div className="py-1 header-top"></div>
        <div className="upper-menu-block">
          <div className="our-container">
            <div className="inner-container d-flex align-items-center justify-content-between mx-auto px-5">
              <div className="logo-block my-2">
                <Link to={"/"} className="d-block">
                  <img src="/images/karv-logo.png" alt="" />
                </Link>
              </div>
              <div className="mobile-icon-responsive">
                <div className="mobile-personalized">
                  <NavLink
                    to={"/personalized"}
                    className="fs-6 fw-semibold nav-items personalized mobile-person"
                    onClick={clearFilters}
                  >
                    <div className="d-flex align-items-center gap-2 animated-text">
                      <span>Personalized</span> <FaEdit />
                    </div>
                  </NavLink>
                </div>
                <GiHamburgerMenu
                  className="hamburgerMenu"
                  onClick={handleSidebarToggle}
                />
                <div className="">
                  <NavLink
                    to={"/cart"}
                    className="position-relative mobile-cart-block"
                    onClick={() => setSidebarToggle(false)}
                  >
                    <FaShoppingCart className="mobile-shop-cart" />
                    <span
                      className={`${totalCartItems > 0 && "c-value-mobile"}`}
                    >
                      {totalCartItems > 0 && `${totalCartItems}`}
                    </span>
                  </NavLink>
                </div>
              </div>
              <div
                className={`main-menu ${sidebarToggle ? "sidebar-active" : ""}`}
                ref={sidebarRef}
              >
                <div className="sidebar-top-navbar">
                  <div className="logo-block my-2">
                    <Link
                      to={"/"}
                      className="d-block"
                      onClick={() => setSidebarToggle(false)}
                    >
                      <img src="/images/karv-logo.png" alt="" />
                    </Link>
                  </div>
                  <RxCross2
                    className="sidebar-close-cross"
                    onClick={() => setSidebarToggle(false)}
                  />
                </div>
                <nav>
                  <ul className="p-0 d-flex align-items-center justify-content-center mb-0 nav-links">
                    <li>
                      <NavLink
                        to={"/personalized"}
                        className="fs-6 fw-semibold nav-items personalized"
                        onClick={clearFilters}
                      >
                        <div className="d-flex align-items-center gap-2 py-2 px-3 animated-text">
                          <span>Personalized</span> <FaEdit />
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
                          clearFilters(); // Clear the filters
                          setSidebarToggle(false); // Close the sidebar
                        }}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/about-us"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
                          clearFilters(); // Clear the filters
                          setSidebarToggle(false); // Close the sidebar
                        }}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/product"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
                          clearFilters(); // Clear the filters
                          setSidebarToggle(false); // Close the sidebar
                        }}
                      >
                        Products
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/b2b"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
                          clearFilters(); // Clear the filters
                          setSidebarToggle(false); // Close the sidebar
                        }}
                      >
                        B2B
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/contact-us"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
                          clearFilters(); // Clear the filters
                          setSidebarToggle(false); // Close the sidebar
                        }}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to={"/blog"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
      clearFilters(); // Clear the filters
      setSidebarToggle(false); // Close the sidebar
    }}
                      >
                        Blog
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        to={"/gallery"}
                        className="fs-6 fw-semibold nav-items"
                        onClick={() => {
                          clearFilters(); // Clear the filters
                          setSidebarToggle(false); // Close the sidebar
                        }}
                      >
                        Gallery
                      </NavLink>
                    </li>
                    <li className="mobile-pc-cart">
                      <NavLink
                        to={"/cart"}
                        className="fs-6 fw-semibold nav-items shop-cart-label"
                        onClick={() => {
                          clearFilters(); // Clear the filters
                          setSidebarToggle(false); // Close the sidebar
                        }}
                      >
                        {/* Shopping {totalCartItems > 0 && `(${totalCartItems})`} */}
                        <FaShoppingCart className="shop-cart" />
                        <span
                          className={`${
                            totalCartItems > 0 && "c-value-mobile"
                          }`}
                        >
                          {totalCartItems > 0 && `${totalCartItems}`}
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
