import React, { useContext, useEffect, useRef, useState } from "react";
import Product from "../../components/Product";
import Loading from "../../components/Loading";
import { PersonalizedContext } from "../../contexts/PersonalizedContext";
import axios from "axios";
import MetaDescTag from "../../components/MetaDescTag";

const Personalized = () => {
  const {
    allProducts,
    loading,
    currentPage,
    setCurrentPage,
    postsPerPage,
    totalPages,
    selectedFilters,
    setSelectedFilters,
    isLastPage,
  } = useContext(PersonalizedContext);

  const videoRef = useRef(null);

  // Programmatically play the video when the component loads
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Autoplay started successfully
          })
          .catch((error) => {
            // Autoplay failed (possibly due to browser restrictions)
            console.log("Autoplay failed:", error);
          });
      }
    }
  }, []);

  const [filterType, setFilterType] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);

  // Fetch filter categories from the API
  const fetchFilterType = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
        { prodType: "Personalized" } // Make sure you're passing the correct prodType value
      );
      console.log("API Response:", response.data); // Log to confirm response structure

      if (response.data && response.data.option) {
        setFilterType(response.data.option);
        console.log("Filter Type:", response.data.option); // Log the filter options
      } else {
        console.log("No filter options found");
      }

      setLoadingFilters(false);
    } catch (error) {
      console.error("Error fetching filter categories:", error);
      setLoadingFilters(false);
    }
  };

  // Reset the current page and fetch filter types when the component mounts
  useEffect(() => {
    // setCurrentPage(1);
    fetchFilterType();
  }, []);

  // Handle filter change
  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prev) =>
      checked ? [...prev, value] : prev.filter((filter) => filter !== value)
    );
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  console.log("allProducts ");

  return (
    <>
      <MetaDescTag
        title={"Products | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="personalized-page">
        <div className="py-5 personalized-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 product-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">
                  Personalized
                </h1>
              </div>
              {/* {allProducts?.length > 0 ? ( */}
              <div className="personalized-page-body">
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="filter-section">
                      <p className="mb-2">Categories</p>
                      {loadingFilters ? (
                        <Loading />
                      ) : (
                        <div className="filter-checkboxes">
                          {filterType.length > 0 ? (
                            filterType.map((item, index) => (
                              <div className="form-check" key={index}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={item}
                                  id={`flexCheckDefault-${index}`}
                                  checked={selectedFilters.includes(item)}
                                  onChange={handleFilterChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexCheckDefault-${index}`}
                                >
                                  {item}
                                </label>
                              </div>
                            ))
                          ) : (
                            <p>No filters available</p>
                          )}
                        </div>
                      )}
                    </div>

                    <div>
                      <div className="d-grid gap-3 product-page-row">
                        {/* {allProducts?.map((product) => (
                            <Product
                              key={product?.pkProdId}
                              product={product}
                            />
                          ))} */}
                        {loading ? (
                          <Loading />
                        ) : allProducts.length === 0 ? (
                          <p className="not-product-found">
                            No products found for the selected filters. Please
                            adjust your filters.
                          </p>
                        ) : (
                          allProducts?.map((product) => (
                            <Product
                              key={product?.pkProdId}
                              product={product}
                            />
                          ))
                        )}
                      </div>
                      <div className="mt-5 product-page-pagination">
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className={`pagination-disable-btn ${
                            currentPage === 1 ? "prev-button-disabled" : ""
                          }`}
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={isLastPage}
                          className={`pagination-next-btn ${
                            currentPage === totalPages
                              ? "next-button-disabled"
                              : ""
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* ) : (
                <p className="mb-0 text-center" style={{ fontSize: "40px" }}>
                  Products Not Found
                </p>
              )} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Personalized;
