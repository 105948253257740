import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { CartContext } from "../../contexts/CartContext";
import axios from "axios";

const CheckOut = () => {
  const { cartItems, getTotalPrice } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhNo, setClientPhNo] = useState("");
  const [clientAdd, setClientAdd] = useState("");
  const [clientAdd2, setClientAdd2] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientState, setClientState] = useState("");
  const [clientPincode, setClientPincode] = useState("");
  const [clientAlterNateContact, setClientAlterNateContact] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [buyNowDetails, setBuyNowDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState({
    userEmail: "",
    userName: "",
    orderId: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("Online/Prepaid");
  const [isCod, setIsCod] = useState(0);
  const [isPrepaid, setIsPrepaid] = useState(1);
  const [isShipped, setIsShipped] = useState(0);

  // useEffect to handle data from "Buy Now" action or localStorage
  useEffect(() => {
    if (
      location.state &&
      location.state.productDetails &&
      location.state.amount
    ) {
      setBuyNowDetails({
        productDetails: location.state.productDetails,
        amount: location.state.amount,
      });
    }

    // Retrieve cartItems from localStorage if they exist
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    // if (storedCartItems && storedCartItems.length > 0) {}
  }, [location.state]);

  const handleClientNameChange = (e) => setClientName(e.target.value);
  const handleClientEmailChange = (e) => setClientEmail(e.target.value);
  // const handleClientPhNoChange = (e) => setClientPhNo(e.target.value);
  const handleClientAddChange = (e) => setClientAdd(e.target.value);
  const handleClientAdd2Change = (e) => setClientAdd2(e.target.value);
  const handleClientCityChange = (e) => setClientCity(e.target.value);
  const handleClientStateChange = (e) => setClientState(e.target.value);
  const handleClientPincodeChange = (e) => setClientPincode(e.target.value);
  // const handleClientAlterNateContact = (e) =>
  //   setClientAlterNateContact(e.target.value);
  const handleClientPhNoChange = (e) => {
    const value = e.target.value;

    // Only allow numbers and restrict to a maximum of 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setClientPhNo(value);
    }
  };
  const handleClientAlterNateContact = (e) => {
    const value = e.target.value;

    // Only allow numbers and restrict to a maximum of 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setClientAlterNateContact(value);
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!clientName.trim()) {
      errors.clientName = "Name is required";
      isValid = false;
    }

    // if (!clientEmail.trim()) {
    //   errors.clientEmail = "Email is required";
    //   isValid = false;
    // } else if (!/\S+@\S+\.\S+/.test(clientEmail)) {
    //   errors.clientEmail = "Email is invalid";
    //   isValid = false;
    // }

    // if (!clientEmail.trim()) {
    //   errors.clientEmail = "Email is required";
    //   isValid = false;
    // } else if (!/^[\w-.]+@([\w-]+\.)+([a-zA-Z]{2,4}|[a-zA-Z]{2})$/.test(clientEmail)) {
    //   // Updated regex to allow for .com, .in, and other valid domains
    //   errors.clientEmail = "Email is invalid";
    //   isValid = false;
    // }

    // Advanced Email validation
    if (!clientEmail.trim()) {
      errors.clientEmail = "Email is required";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(clientEmail)
    ) {
      errors.clientEmail =
        "Email is invalid. Ensure it follows the format: example@domain.com";
      isValid = false;
    }

    if (!clientPhNo.trim()) {
      errors.clientPhNo = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(clientPhNo)) {
      errors.clientPhNo = "Phone number must be 10 digits";
      isValid = false;
    }

    if (!clientAlterNateContact.trim()) {
      errors.clientAlterNateContact = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(clientAlterNateContact)) {
      errors.clientAlterNateContact = "Phone number must be 10 digits";
      isValid = false;
    }

    // if (!clientAdd.trim()) {
    //   errors.clientAdd = "Address is required";
    //   isValid = false;
    // }
    // Address validation
    if (!clientAdd.trim()) {
      errors.clientAdd = "Address is required";
      isValid = false;
    } else if (clientAdd.length <= 3) {
      errors.clientAdd = "Address must be more than 3 characters";
      isValid = false;
    }

    if (!clientCity.trim()) {
      errors.clientCity = "City is required";
      isValid = false;
    }

    if (!clientState.trim()) {
      errors.clientState = "State is required";
      isValid = false;
    }

    if (!clientPincode.trim()) {
      errors.clientPincode = "Pincode is required";
      isValid = false;
    } else if (!/^\d{6}$/.test(clientPincode)) {
      errors.clientPincode = "Pincode must be 6 digits";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    setIsCod(e.target.value === "Cash on Delivery" ? 1 : 0);
    setIsPrepaid(e.target.value === "Online/Prepaid" ? 1 : 0);
    // setIsShipped(e.target.value === 0);
  };

  const handleRazorpaySuccess = async (response, pkOrderId) => {
    try {
      const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
        response;

      if (!razorpay_payment_id || !razorpay_order_id || !razorpay_signature) {
        throw new Error("Missing payment details");
      }

      // Prepare data to send to your backend
      const requestData = {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const backendResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/order/paymentCallBack`,
        requestOptions
      );

      const responseText = await backendResponse.text();

      if (backendResponse.ok) {
        // Split response text to extract payment status and amount
        const [paymentStatus, amount] = responseText.split(",");
        const parsedAmount = parseFloat(amount.trim());

        // Check if the status is "Payment Status Updated" and amount is valid
        if (
          paymentStatus.trim() === "Payment Status Updated" &&
          !isNaN(parsedAmount)
        ) {
          toast.success("Payment successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });

          // Store order details
          setOrderDetails({
            userEmail: clientEmail,
            userName: clientName,
            orderId: razorpay_order_id, // Assuming order_id is the correct field here
          });

          // // Call email confirmation API
          // await sendConfirmationEmail(
          //   clientEmail,
          //   clientName,
          //   razorpay_order_id
          // );

          await sendOrderNotifyEmail(
            buyNowDetails?.productDetails[0]?.productName || "",
            buyNowDetails ? 1 : cartItems.length,
            parsedAmount.toString(),
            clientName,
            clientEmail,
            clientPhNo,
            clientAdd,
            razorpay_order_id
          );

          // Pass the productDetails and amount to handleLogin
          const productDetails = buyNowDetails
            ? buyNowDetails?.productDetails
            : cartItems;

          await handleLogin(productDetails, razorpay_order_id, pkOrderId);

          // navigate(`/thank-you?amount=${parsedAmount}`);
          navigate(`/`);
        } else {
          toast.error("Payment failed.", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } else {
        toast.error(
          "Failed to place order, status: " + backendResponse.status,
          {
            autoClose: 1000,
            position: "bottom-right",
          }
        );
      }
    } catch (error) {
      toast.error("Error while handling payment success: " + error.message, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const handleRazorpayCancel = async (order_id) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // message: "Payment cancelled by user",
          razorpay_order_id: order_id,
        }),
      };

      const backendResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/order/paymentCancel`,
        requestOptions
      );

      if (!backendResponse.ok) {
        throw new Error("Failed to notify backend about payment cancellation");
      }

      const responseData = await backendResponse.json();
    } catch (error) {
      toast.error("Error while handling payment cancellation: " + error, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const sendConfirmationEmail = async (userEmail, userName, orderId) => {
    try {
      const requestData = {
        userEmail,
        userName,
        orderId,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/confirmOrderEmail`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to send confirmation email");
      }

      const responseData = await response.json();
    } catch (error) {
      toast.error("Error while sending confirmation email: " + error, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const sendOrderNotifyEmail = async (
    prodName,
    quantity,
    amount,
    clientName,
    clientEmail,
    clientPhNo,
    clientAdd,
    orderId
  ) => {
    try {
      const requestData = {
        prodName,
        quantity,
        amount,
        clientName,
        "mailto:clientemail": clientEmail,
        clientPhNo,
        clientAdd,
        orderId,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/orderNotifyEmail`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to send order notification email");
      }

      const responseData = await response.json();
    } catch (error) {
      toast.error("Error while sending order notification email: " + error, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientName,
          clientEmail,
          clientPhNo1: clientPhNo,
          clientPhNo2: clientAlterNateContact,
          clientAdd1: clientAdd,
          clientAdd2: clientAdd2,
          city: clientCity,
          state: clientState,
          pincode: clientPincode,
          country: "India", // Assuming country is India, adjust as needed
          isCod,
          isPrepaid,
          isShipped,
          amount: buyNowDetails
            ? buyNowDetails.amount.toString()
            : getTotalPrice().toString(),
          cartItems: buyNowDetails
            ? buyNowDetails.productDetails.map((item) => ({
                fkImgId: item.selectedColor.pkImgId ?? null,
                customMessage: item.customization.inputValue ?? "",
                zodiacSign: item.customization.selectedZodiacSign ?? "",
                quantity: item.quantity,
              }))
            : cartItems.map((item) => ({
                fkImgId: item.selectedColor.pkImgId ?? null,
                customMessage: item.customization.inputValue ?? "",
                zodiacSign: item.customization.selectedZodiacSign ?? "",
                quantity: item.quantity,
              })),
        }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/order/add`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        setClientName("");
        setClientEmail("");
        setClientPhNo("");
        setClientAlterNateContact("");
        setClientAdd("");
        setClientAdd2("");
        setClientCity("");
        setClientState("");
        setClientPincode("");

        const data = await response.json();
        const orderData = JSON.parse(data.order);
        const order_id = orderData.id;
        const pkOrderId = data.pkOrderId;

        if (paymentMethod === "Online/Prepaid") {
          // Initiate Razorpay payment
          const options = {
            key: "rzp_live_tzzZtL3Wmelx8H",
            amount: orderData.amount,
            currency: "INR",
            name: "Karv Accessories",
            description: "Test Transaction",
            image: "/images/karv-logo.png",
            order_id: order_id,
            handler: (response) => handleRazorpaySuccess(response, pkOrderId),
            prefill: {
              name: clientName,
              email: clientEmail,
              contact: clientPhNo,
            },
            notes: {
              address: clientAdd,
            },
            theme: {
              color: "#3395ff",
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.on("payment.failed", () => handleRazorpayCancel(order_id));
          rzp1.open();
        } else {
          toast.success("Order placed successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });

          // Call email confirmation API only if isCod is 1
          if (isCod === 1) {
            navigate("/");

            await sendConfirmationEmail(clientEmail, clientName, order_id);

            await sendOrderNotifyEmail(
              buyNowDetails?.productDetails[0]?.productName || "",
              buyNowDetails ? 1 : cartItems.length,
              orderData.amount.toString(),
              clientName,
              clientEmail,
              clientPhNo,
              clientAdd,
              order_id
            );
          }

          // Navigate to thank you or confirmation page
          // navigate(`/thank-you`);
        }
      } catch (error) {
        toast.error("Error during checkout: " + error, {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    }
  };

  const getInputClass = (fieldName) => {
    if (formErrors[fieldName]) {
      return "form-control is-invalid";
    } else if (
      (fieldName === "clientPhNo" && clientPhNo.trim().length === 10) ||
      (fieldName === "clientEmail" && /\S+@\S+\.\S+/.test(clientEmail))
    ) {
      return "form-control is-valid";
    } else {
      return "form-control";
    }
  };

  const handleLogin = async (productDetails, razorpay_order_id, pkOrderId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/login`,
        {
          email: "mukeshkmwl@gmail.com",
          password: "Mukesh@123",
        }
      );

      if (response.data.token) {
        localStorage.setItem("shiprocketToken", response.data.token);
        await checkServiceability(productDetails, razorpay_order_id, pkOrderId);
      } else {
        // console.error("Login failed, token not received.");
      }
    } catch (error) {
      // console.error("Login error:", error);
    }
  };

  const checkServiceability = async (
    productDetails,
    razorpay_order_id,
    pkOrderId
  ) => {
    const staticPickupPostcode = "400705"; // Static pickup postcode
    const staticWeight = 0.5; // Static weight

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shiprocket/serviceability`,
        {
          params: {
            pickup_postcode: staticPickupPostcode,
            delivery_postcode: clientPincode,
            cod: 0,
            weight: staticWeight,
          },
        }
      );

      // Call createOrder after serviceability check
      await createOrder(productDetails, razorpay_order_id, pkOrderId);
    } catch (error) {
      // console.error("Error checking serviceability:", error);
    }
  };

  // serviceability api successfully after calling create shiprocket order
  const createOrder = async (productDetails, razorpay_order_id, pkOrderId) => {
    try {
      // Calculate subtotal
      const subTotal = productDetails.reduce((total, item) => {
        return total + parseFloat(item.prodPrice) * item.quantity;
      }, 0);
      // Get current local date in the desired format
      const orderDate = new Date().toISOString().split("T")[0];

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/createOrder`,
        {
          order_id: razorpay_order_id,
          order_date: orderDate,
          pickup_location: "KARV ACC",
          channel_id: "",
          comment: "",
          reseller_name: "",
          company_name: "",
          billing_customer_name: clientName,
          billing_last_name: "",
          billing_address: clientAdd,
          billing_address_2: clientAdd2,
          billing_isd_code: "",
          billing_city: clientCity,
          billing_pincode: clientPincode,
          billing_state: clientState,
          billing_country: "India",
          billing_email: clientEmail,
          billing_phone: clientPhNo,
          billing_alternate_phone: clientAlterNateContact || "",
          shipping_is_billing: "1",
          shipping_customer_name: "",
          shipping_last_name: "",
          shipping_address: "",
          shipping_address_2: "",
          shipping_city: "",
          shipping_pincode: "",
          shipping_country: "",
          shipping_state: "",
          shipping_email: "",
          shipping_phone: "",
          order_items: productDetails.map((item) => ({
            name: item.prodName,
            sku: item.prodCode,
            units: item.quantity, // Assuming 1 unit per item
            selling_price: parseFloat(item.prodPrice),
            discount: "",
            tax: "",
            hsn: "",
          })),
          payment_method: isPrepaid === "1" ? "Prepaid" : "COD",
          shipping_charges: "",
          giftwrap_charges: "",
          transaction_charges: "",
          total_discount: "",
          sub_total: subTotal,
          length: "10", // Example values, adjust as needed
          breadth: "10",
          height: "10",
          weight: "0.80",
          ewaybill_no: "",
          customer_gstin: "",
          invoice_number: "",
          order_type: "",
        }
      );

      // After order creation, assign courier
      await assignCourier(
        response.data.shipment_id,
        response.data.order_id,
        razorpay_order_id,
        pkOrderId,
        clientName,
        clientEmail
      );
    } catch (error) {
      // console.error("Error creating order:", error);
    }
  };

  // shiprocket order api successfully after calling assign courier awb
  const assignCourier = async (
    shipmentId,
    shipOrderId,
    razorpay_order_id,
    pkOrderId,
    clientName,
    clientEmail
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/assign-awb`,
        {
          shipment_id: shipmentId,
        }
      );

      // Ensure response.data.response.data contains awb_code
      const awbCode = response.data.response?.data?.awb_code;

      if (awbCode) {
        // After assigning the courier, generate pickup
        await generatePickup(
          shipmentId,
          shipOrderId,
          razorpay_order_id,
          pkOrderId,
          clientName,
          clientEmail,
          awbCode
        );
      } else {
        // console.error("AWB code not found in the response.");
      }
    } catch (error) {
      // console.error("Error assigning courier:", error);
    }
  };

  // awb api successfully after calling generate pickup
  const generatePickup = async (
    shipmentId,
    shipOrderId,
    razorpay_order_id,
    pkOrderId,
    clientName,
    clientEmail,
    awbCode
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-pickup`,
        {
          shipment_id: String(shipmentId),
        }
      );

      // After generating pickup, generate manifest
      await generateManifest(
        shipmentId,
        shipOrderId,
        razorpay_order_id,
        pkOrderId,
        clientName,
        clientEmail,
        awbCode
      );
    } catch (error) {
      // console.error("Error generating pickup:", error);
    }
  };

  // generate pickup api successfully after calling generate manifest
  const generateManifest = async (
    shipmentId,
    shipOrderId,
    razorpay_order_id,
    pkOrderId,
    clientName,
    clientEmail,
    awbCode
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-manifest`,
        {
          shipment_id: String(shipmentId),
        }
      );

      const manifestUrl1 = response.data.manifest_url;

      // After generating manifest, print the manifest
      await printManifest(
        shipOrderId,
        shipmentId,
        razorpay_order_id,
        pkOrderId,
        clientName,
        clientEmail,
        awbCode,
        manifestUrl1
      );
    } catch (error) {
      // console.error("Error generating manifest:", error);
    }
  };

  // generate manifest api successfully after calling generate print manifest url
  const printManifest = async (
    shipOrderId,
    shipmentId,
    razorpay_order_id,
    pkOrderId,
    clientName,
    clientEmail,
    awbCode,
    manifestUrl1
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/print-manifest`,
        {
          order_ids: [shipOrderId],
        }
      );

      const manifestUrl2 = response.data.manifest_url;

      // After printing manifest, generate the shipping label
      await generateLabel(
        shipOrderId,
        shipmentId,
        razorpay_order_id,
        pkOrderId,
        clientName,
        clientEmail,
        awbCode,
        manifestUrl1,
        manifestUrl2
      );
    } catch (error) {
      // console.error("Error printing manifest:", error);
    }
  };

  // generate print manifest url api successfully after calling generate label
  const generateLabel = async (
    shipOrderId,
    shipmentIds,
    razorpay_order_id,
    pkOrderId,
    clientName,
    clientEmail,
    awbCode,
    manifestUrl1,
    manifestUrl2
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-label`,
        {
          shipment_id: [shipmentIds],
        }
      );

      const labelUrl = response.data.label_url;

      // After shipping label, print invoices
      await printInvoice(
        shipOrderId,
        shipmentIds,
        razorpay_order_id,
        pkOrderId,
        clientName,
        clientEmail,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl
      );
    } catch (error) {
      // console.error("Error generating shipping label:", error);
    }
  };

  // generate label api successfully after calling print invoices
  const printInvoice = async (
    shipOrderId,
    shipmentIds,
    razorpay_order_id,
    pkOrderId,
    clientName,
    clientEmail,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shiprocket/generate-invoice`,
        {
          ids: [shipOrderId],
        }
      );

      const invoiceUrl = response.data.invoice_url;

      // After print invoices, call awb for track order
      await trackAWB(
        shipOrderId,
        shipmentIds,
        razorpay_order_id,
        clientName,
        clientEmail,
        awbCode
      );

      // call the mailing API all PDF send to this API
      await sendShiprocketDocuments(
        shipOrderId,
        shipmentIds,
        razorpay_order_id,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl,
        invoiceUrl
      );

      // call the mailing API all user details send to this API
      await sendUserInformation(
        shipOrderId,
        shipmentIds,
        razorpay_order_id,
        clientName,
        clientEmail,
        awbCode,
        manifestUrl1,
        manifestUrl2,
        labelUrl,
        invoiceUrl
      );

      // call the pkOrderId API
      await updateUserClick(pkOrderId);
    } catch (error) {
      // console.error("Error print invoices shipping:", error);
    }
  };

  // track AWB last api
  const trackAWB = async (
    shipOrderId,
    shipmentIds,
    razorpay_order_id,
    clientName,
    clientEmail,
    awbCode
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shiprocket/track-shipment/${awbCode}`
      );
    } catch (error) {
      // console.error("Error checking serviceability:", error);
    }
  };

  // final API for all PDFs url
  const sendShiprocketDocuments = async (
    shipOrderId,
    shipmentIds,
    razorpay_order_id,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl,
    invoiceUrl
  ) => {
    const data = {
      manifestUrl1,
      manifestUrl2,
      labelUrl,
      invoiceUrl,
      awbNumber: awbCode,
      orderNumber: razorpay_order_id,
      shipmentNumber: shipmentIds,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/sentShiprocketDocuments`,
        data
      );
    } catch (error) {
      // console.error(
      //   "Error:",
      //   error.response ? error.response.data : error.message
      // );
    }
  };

  // final API for all user details url
  const sendUserInformation = async (
    shipOrderId,
    shipmentIds,
    razorpay_order_id,
    clientName,
    clientEmail,
    awbCode,
    manifestUrl1,
    manifestUrl2,
    labelUrl,
    invoiceUrl
  ) => {
    const data = {
      orderId: razorpay_order_id,
      userName: clientName,
      userEmail: clientEmail,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/sentOrderConfirmationEmailToUser`,
        data
      );
    } catch (error) {
      // console.error(
      //   "Error:",
      //   error.response ? error.response.data : error.message
      // );
    }
  };

  const updateUserClick = async (pkOrderId) => {
    const requestBody = {
      isShipped: "1",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/order/update/${pkOrderId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
    } catch (error) {
      // console.error("Error updating order:", error);
    }
  };

  return (
    <>
      <section className="checkout-page">
        <div className="py-5 checkout-page-block">
          <div className="our-container">
            <div className="inner-container mx-auto">
              <div className="checkout-page-heading mb-4">
                <h1 className="fs-2 fw-medium text-capitalize">
                  Your Checkout
                </h1>
              </div>
              <div className="checkout-page-row-block">
                <div className="col-md-8 checkout-page-row-left-block">
                  <div className="row">
                    <div className="checkout-page-body">
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label
                            htmlFor="clientPhNo"
                            className="form-label fw-semibold fs-5"
                          >
                            Contact
                          </label>
                          <input
                            type="number"
                            className={getInputClass("clientPhNo")}
                            id="clientPhNo"
                            placeholder="Your Contact Number"
                            autoComplete="off"
                            value={clientPhNo}
                            onChange={handleClientPhNoChange}
                            maxLength={10}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientPhNo}
                          </div>
                        </div>
                        <div className="col-12">
                          <input
                            type="email"
                            className={getInputClass("clientEmail")}
                            id="clientEmail"
                            placeholder="Your Email"
                            autoComplete="off"
                            value={clientEmail}
                            onChange={handleClientEmailChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientEmail}
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="clientName"
                            className="form-label fw-semibold fs-5"
                          >
                            Delivery
                          </label>
                          <input
                            type="text"
                            className={getInputClass("clientName")}
                            id="clientName"
                            placeholder="Your Name"
                            autoComplete="off"
                            value={clientName}
                            onChange={handleClientNameChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientName}
                          </div>
                        </div>
                        <div className="col-12">
                          <textarea
                            className={getInputClass("clientAdd")}
                            id="clientAdd"
                            rows="2"
                            placeholder="Address Line 1"
                            autoComplete="off"
                            value={clientAdd}
                            onChange={handleClientAddChange}
                          ></textarea>
                          <div className="invalid-feedback">
                            {formErrors.clientAdd}
                          </div>
                        </div>
                        <div className="col-12">
                          <textarea
                            type="text"
                            className={getInputClass("clientAdd2")}
                            id="clientAdd2"
                            rows="2"
                            placeholder="Address Line 2 (optional)"
                            autoComplete="off"
                            value={clientAdd2}
                            onChange={handleClientAdd2Change}
                          ></textarea>
                        </div>
                        <div className="col-4">
                          <input
                            type="text"
                            className={getInputClass("clientCity")}
                            id="clientCity"
                            placeholder="City"
                            autoComplete="off"
                            value={clientCity}
                            onChange={handleClientCityChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientCity}
                          </div>
                        </div>
                        <div className="col-4">
                          <input
                            type="text"
                            className={getInputClass("clientState")}
                            id="clientState"
                            placeholder="State"
                            autoComplete="off"
                            value={clientState}
                            onChange={handleClientStateChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientState}
                          </div>
                        </div>
                        <div className="col-4">
                          <input
                            type="number"
                            className={getInputClass("clientPincode")}
                            id="clientPincode"
                            placeholder="Pincode"
                            autoComplete="off"
                            value={clientPincode}
                            onChange={handleClientPincodeChange}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientPincode}
                          </div>
                        </div>
                        <div className="col-12">
                          <input
                            type="number"
                            className={getInputClass("clientAlterNateContact")}
                            id="clientAlterNateContact"
                            placeholder="AlterNate Contact No"
                            autoComplete="off"
                            value={clientAlterNateContact}
                            onChange={handleClientAlterNateContact}
                            maxLength={10}
                          />
                          <div className="invalid-feedback">
                            {formErrors.clientAlterNateContact}
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="paymentMethod"
                            className="form-label fw-semibold fs-5"
                          >
                            Payment Method
                          </label>
                          <select
                            id="paymentMethod"
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                            className="form-select"
                          >
                            <option value="Online/Prepaid">
                              Online/Prepaid
                            </option>
                            <option value="Cash on Delivery">
                              Cash on Delivery
                            </option>
                          </select>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-center">
                          <button type="submit" className="pay-now-btn mx-2">
                            {paymentMethod === "Cash on Delivery"
                              ? "Submit"
                              : "Pay Now"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 checkout-page-row-right-block">
                  <div className="subtotal-checkout-block rounded-1">
                    <div className="subtotal-block mb-2">
                      <div className="h5 mb-0">Subtotal</div>
                      <div className="h3 mb-0">
                        <FaIndianRupeeSign className="checkout-rupees-sign" />
                        {buyNowDetails ? buyNowDetails.amount : getTotalPrice()}
                      </div>
                    </div>
                    <div className="shipping-block mb-2">
                      <div className="h5 mb-0">Shipping</div>
                      <div className="h3 mb-0">Free</div>
                    </div>
                    <div className="total-amount-block">
                      <div className="h5 mb-0">Total</div>
                      <div className="h3 mb-0">
                        <FaIndianRupeeSign className="checkout-rupees-sign" />
                        {buyNowDetails ? buyNowDetails.amount : getTotalPrice()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckOut;
