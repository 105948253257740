import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaIndianRupeeSign,
  FaShareFromSquare,
  FaMinus,
  FaPlus,
} from "react-icons/fa6";
import {
  FaFacebookSquare,
  FaInstagram,
  FaWhatsappSquare,
  FaCopy,
} from "react-icons/fa";
import { MdArrowBackIos } from "react-icons/md";
import Loading from "../../components/Loading";
import { zodiacData } from "../../data";
import { CartContext } from "../../contexts/CartContext";
import { ProductContext } from "../../contexts/ProductContext";
import { toast } from "react-toastify";
import MetaDescTag from "../../components/MetaDescTag";

const SingleProduct = () => {
  const { getProductDetailsById, getSuggestedProducts } =
    useContext(ProductContext);
  const { addToCart } = useContext(CartContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const [productDetails, setProductDetails] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedZodiacSign, setSelectedZodiacSign] = useState("");
  const [customizeBtn, setCustomizeBtn] = useState(false);
  const [currentCustomizingImage, setCurrentCustomizingImage] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Color code to conditionally change text color
  const textColor = selectedColor?.imageColor === "#000000" && "#FFFFFF";

  // Find the Zodiac sign object based on the selected sign
  const zodiacSign = zodiacData.find(
    (zodiac) => zodiac.sign === selectedZodiacSign
  );

  // Function to get the appropriate zodiac image URL
  const zodiacImageUrl =
    selectedColor?.imageColor === "#000000"
      ? zodiacSign?.imageWhiteUrl
      : zodiacSign?.imageUrl;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await getProductDetailsById(id);
        if (response && response.masterData && response.masterData.length > 0) {
          const product = response.masterData[0]; // Access the first item
          setProductDetails(product);
          if (product.info.length > 0) {
            setSelectedColor(product.info[0]);
            setMainImage(product.info[0].imagePath[0]);
          }
        }
      } catch (error) {
        console.error("Failed to fetch product details:", error);
      }
    };

    fetchProductDetails();
  }, [id, getProductDetailsById]);

  useEffect(() => {
    const fetchSuggestedProducts = async () => {
      const suggestions = await getSuggestedProducts(id);
      setSuggestedProducts(suggestions);
    };

    fetchSuggestedProducts();
  }, [id, getSuggestedProducts]);

  useEffect(() => {
    if (productDetails) {
      const basePrice = parseFloat(productDetails.prodPrice) || 0;
      setTotalPrice(basePrice * quantity);
    }
  }, [productDetails, quantity]);

  if (!productDetails) return <Loading />;

  // Current page URL
  const productUrl = window.location.href;

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setMainImage(color?.imagePath[0]);
    setShowShareOptions(false);
  };

  const handleImageClick = (image) => {
    setMainImage(image);
    setInputValue("");
    setSelectedZodiacSign("");
    setCurrentCustomizingImage(image);
    setCustomizeBtn(false);
    setShowShareOptions(false);
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/people/Karv-Accessories/61556072433937/?u=${encodeURIComponent(
      productUrl
    )}`;
    window.open(facebookUrl, "_blank");
  };

  const handleInstagramShare = () => {
    const instagramUrl = `https://www.instagram.com/karvaccessories.official/?url=${encodeURIComponent(
      productUrl
    )}`;
    window.open(instagramUrl, "_blank");
  };

  const handleWhatsappShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      productUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(productUrl).then(
      () => {
        toast.success("Link copied to clipboard!", {
          autoClose: 1000,
          position: "bottom-right",
        });
      },
      (err) => {
        toast.error(err, {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    );
  };

  const zodiacArray = Object.entries(zodiacData).map(([sign, data]) => ({
    sign,
    ...data,
  }));

  const handleInputChange = (event) => {
    // // setInputValue(event.target.value);

    // let value = event.target.value;

    // // Ensure the first word's first letter is capitalized, rest lowercase
    // const capitalizedValue = value
    //   .toLowerCase()
    //   .replace(/^\w/, (c) => c.toUpperCase());

    // setInputValue(capitalizedValue);

    // Regular expression to allow only alphabetic characters and spaces
    
    
    let value = event.target.value;
    const regex = /^[A-Za-z\s]*$/;

    if (regex.test(value)) {
      // Capitalize only the first letter of the first word
      const capitalizedValue = value
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase());

      setInputValue(capitalizedValue);
    }
  };

  const handleSelectChange = (sign) => {
    setSelectedZodiacSign(sign);
  };

  const handleDropdownChange = (event) => {
    setSelectedZodiacSign(event.target.value);
  };

  const handleCustomizeBtn = () => {
    setCustomizeBtn(!customizeBtn);
    if (selectedColor?.imagePath?.length > 0) {
      const lastImage =
        selectedColor.imagePath[selectedColor.imagePath.length - 1];
      setMainImage(lastImage);
      setCurrentCustomizingImage(lastImage);
      setShowShareOptions(false);
    }
  };

  const handleAddToCart = () => {
    addToCart(productDetails, selectedColor, quantity, {
      inputValue,
      selectedZodiacSign,
    });
    toast.success("Added to Bag", {
      autoClose: 1000,
      position: "bottom-right",
    });
  };

  const handleBuyNow = () => {
    const productData = {
      prodName: productDetails.prodName,
      prodCode: productDetails.prodCode,
      prodBrand: productDetails.prodBrand,
      prodPrice: productDetails.prodPrice,
      prodDesc: productDetails.prodDesc,
      isCustomized: productDetails.isCustomized,
      isActive: productDetails.isActive,
      pkProdId: productDetails.pkProdId,
      selectedColor: {
        imageColor: selectedColor.imageColor,
        imageName: selectedColor.imageName,
        productName: selectedColor.productName,
        isActive: selectedColor.isActive,
        imagePath: selectedColor.imagePath,
        pkImgId: selectedColor.pkImgId,
      },
      quantity: quantity,
      customization: {
        inputValue: inputValue,
        selectedZodiacSign: selectedZodiacSign,
      },
      id: `${productDetails.pkProdId}-${selectedColor.imageColor}-{"inputValue":"${inputValue}","selectedZodiacSign":"${selectedZodiacSign}"}`,
    };

    navigate("/checkout", {
      state: {
        productDetails: [productData],
        amount: totalPrice,
      },
    });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const getDescriptionSection = (sectionName) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(productDetails.prodDesc, "text/html");

    const allHeadings = doc.querySelectorAll("h2");
    let sectionContent = "";

    allHeadings.forEach((heading) => {
      const headingText = heading.textContent.trim();
      if (headingText.startsWith(sectionName)) {
        // Get the next sibling (the ul or section content)
        let nextElement = heading.nextElementSibling;

        while (nextElement && nextElement.tagName !== "H2") {
          sectionContent += nextElement.outerHTML;
          nextElement = nextElement.nextElementSibling;
        }
      }
    });

    return sectionContent || `No ${sectionName} available.`;
  };

  return (
    <>
    <MetaDescTag
        title={"Products | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="product-single-page">
        <div className="py-5 product-single-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <button
                onClick={handleBackClick}
                className="back-btn-per-page mb-3"
              >
                <MdArrowBackIos className="back-icon-per-page" />
                Back
              </button>
              <div className="row product-single-page-block-row">
                <div className="col-md-6 mb-3">
                  <div className="row gap-3 px-3 main-row-for-product-single-page">
                    <div className="col-md-2 d-flex align-items-start justify-content-center product-single-page-img-grp">
                      <div className="row gap-3 product-single-page-img-group">
                        {selectedColor?.imagePath?.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={productDetails?.prodName}
                            onClick={() => handleImageClick(image)}
                            className={`thumbnail ${
                              mainImage === image ? "active" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="col-md-9 d-flex align-items-center justify-content-center position-relative product-single-page-main-img">
                      <img
                        src={mainImage}
                        onClick={() => setShowShareOptions(false)}
                        alt={productDetails?.prodName}
                      />
                      {currentCustomizingImage === mainImage && (
                        <>
                          <div className="product-single-page-main-image-current-details">
                            <span
                              className="text-capitalize"
                              style={{ color: textColor }}
                            >
                              {inputValue}
                            </span>
                          </div>
                          <div className="zodiacs-flower-upper-image">
                            {selectedZodiacSign && (
                              <img
                                src={zodiacImageUrl}
                                alt={selectedZodiacSign}
                              />
                            )}
                          </div>
                        </>
                      )}
                      <div className="mt-2 position-absolute share-button-product">
                        <p className="mb-0">
                          <FaShareFromSquare
                            className="fs-4 share-button-icon mb-2"
                            size={25}
                            onClick={() => setShowShareOptions((prev) => !prev)}
                          />
                          {showShareOptions && (
                            <span className="d-flex align-items-center justify-content-between gap-3 flex-column share-button-options">
                              <FaFacebookSquare
                                size={25}
                                onClick={handleFacebookShare}
                                className="product-share-icon-all"
                              />
                              <FaInstagram
                                size={25}
                                onClick={handleInstagramShare}
                                className="product-share-icon-all"
                              />
                              <FaWhatsappSquare
                                size={25}
                                onClick={handleWhatsappShare}
                                className="product-share-icon-all"
                              />
                              <FaCopy
                                size={25}
                                onClick={handleCopyLink}
                                className="product-share-icon-all"
                              />
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product-single-page-details">
                    <h1 className="fs-4 fw-medium mb-3">
                      {productDetails?.prodName}
                    </h1>
                    <div className="d-flex align-items-center mb-3 gap-2 product-price">
                      <FaIndianRupeeSign className="fs-6 fw-medium mb-0 price-icon" />
                      <div className="h3 fs-5 fw-medium mb-0">
                        {productDetails?.prodPrice}
                      </div>
                    </div>
                    <div className="my-4 product-single-page-details-fields">
                      <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
                        {productDetails?.info?.some(
                          (color) => color?.imageColor
                        ) && (
                          <>
                            <label
                              htmlFor="size"
                              className="fs-6 text-capitalize mb-1 fw-medium"
                            >
                              Colors
                            </label>
                            <div className="row justify-content-start gap-3 m-0 product-single-page-colors-group">
                              {productDetails?.info?.map((color, index) => (
                                <div
                                  key={index}
                                  onClick={() => handleColorChange(color)}
                                  className={`col fs-6 text-uppercase radio-btn-color ${
                                    selectedColor?.imageColor ===
                                    color?.imageColor
                                      ? "active"
                                      : ""
                                  }`}
                                  style={{
                                    backgroundColor: color.imageColor,
                                    color: "#ffffff",
                                    border:
                                      selectedColor?.imageColor ===
                                      color?.imageColor
                                        ? "2px solid #000"
                                        : "none",
                                  }}
                                ></div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
                        <label
                          htmlFor="size"
                          className="fs-6 text-capitalize mb-1 fw-medium"
                        >
                          Quantity
                        </label>
                        <div className="row justify-content-start gap-3 m-0 product-single-page-quantity">
                          <div className="quantity-block">
                            <button
                              className="quantity-btn"
                              onClick={() =>
                                setQuantity((prevQuantity) =>
                                  prevQuantity > 1 ? prevQuantity - 1 : 1
                                )
                              }
                            >
                              <FaMinus />
                            </button>
                            <span className="quantity-value">{quantity}</span>
                            <button
                              className="quantity-btn"
                              onClick={() =>
                                setQuantity((prevQuantity) => prevQuantity + 1)
                              }
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                      </div>
                      {productDetails?.isCustomized === "1" && (
                        <div className="d-flex align-items-center justify-content-end">
                          <span
                            className={`product-single-page-customize-button mt-2 mb-3 btn rounded-0 p-0 ${
                              customizeBtn ? "active" : ""
                            }`}
                            onClick={handleCustomizeBtn}
                          >
                            Customize Your Product
                          </span>
                        </div>
                      )}
                      {customizeBtn &&
                        currentCustomizingImage === mainImage && (
                          <>
                            <div className="mb-3 d-flex justify-content-center flex-column product-single-page-form-group">
                              <label
                                htmlFor="size"
                                className="fs-6 text-capitalize mb-1 fw-medium"
                              >
                                Your First Name
                              </label>
                              <input
                                type="text"
                                id="name"
                                autoComplete="off"
                                onChange={handleInputChange}
                                value={inputValue}
                                className="fw-normal"
                                maxLength={10}
                                placeholder="Enter custom text"
                              />
                            </div>
                            <div className="mb-4 d-flex justify-content-center flex-column product-single-page-form-group">
                              <label
                                htmlFor="size"
                                className="fs-6 text-capitalize mb-1 fw-medium"
                              >
                                Your Zodiac
                              </label>
                              <div className="row m-0 justify-content-center">
                                {isMobile ? (
                                  <select
                                    value={selectedZodiacSign}
                                    onChange={handleDropdownChange}
                                    className="form-select"
                                  >
                                    <option value="">Select Zodiac Sign</option>
                                    {zodiacArray.map((signData, index) => (
                                      <option key={index} value={signData.sign}>
                                        {signData.sign}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  <div className="row justify-content-center p-0">
                                    {zodiacArray.map((signData, index) => (
                                      <div
                                        key={index}
                                        className={`col-auto col-sm-3 col-md-3 zodiac-box ${
                                          selectedZodiacSign === signData.sign
                                            ? "selected"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleSelectChange(signData.sign)
                                        }
                                        style={{
                                          border: "1px solid #ccc",
                                          padding: "10px",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          backgroundColor:
                                            selectedZodiacSign === signData.sign
                                              ? "#0e3c52"
                                              : "#ffffff",
                                          color:
                                            selectedZodiacSign === signData.sign
                                              ? "#ffffff"
                                              : "#000000",
                                        }}
                                      >
                                        {signData.sign}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      <div className="row gap-2 mx-0">
                        <button
                          className="col fs-6 fw-medium text-uppercase product-single-page-form-button"
                          onClick={handleBuyNow}
                        >
                          Buy Now
                        </button>
                        <button
                          className="col fs-6 fw-medium text-uppercase product-single-page-form-button"
                          onClick={handleAddToCart}
                        >
                          Add To Bag
                        </button>
                      </div>
                    </div>
                    <div className="h4 fs-6 fw-medium productDescAccordion">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item">
                          <div className="h2 accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              Product Highlights
                            </button>
                          </div>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{
                                __html:
                                  getDescriptionSection("Product Highlights"),
                              }}
                            />
                          </div>
                        </div>
                        <div className="accordion-item">
                          <div className="h2 accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              Product Details
                            </button>
                          </div>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{
                                __html:
                                  getDescriptionSection("Product Details"),
                              }}
                            />
                          </div>
                        </div>
                        <div className="accordion-item">
                          <div className="h2 accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              Disclaimer
                            </button>
                          </div>
                          <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{
                                __html: getDescriptionSection("Disclaimer"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleProduct;
