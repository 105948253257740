import React, { useState, useContext, useEffect } from "react";
import { BtoBContext } from "../../contexts/BtoBContext";
import BtoBProduct from "../../components/BtoBProduct";
import axios from "axios";
import Loading from "../../components/Loading";
import MetaDescTag from "../../components/MetaDescTag";

const BusinessToBusiness = () => {
  const {
    allProducts,
    loading,
    currentPage,
    setCurrentPage,
    totalPages,
    selectedFilters,
    setSelectedFilters,
    isLastPage,
  } = useContext(BtoBContext);

  const [filterType, setFilterType] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const fetchFilterType = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/product/productCategoryList`,
        { prodType: null }
      );
      if (response.data && response.data.option) {
        setFilterType(response.data.option);
      }
      setLoadingFilters(false);
    } catch (error) {
      console.error("Error fetching filter categories:", error);
      setLoadingFilters(false);
    }
  };

  useEffect(() => {
    // setCurrentPage(1);
    fetchFilterType();
  }, []);

  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prev) =>
      checked ? [...prev, value] : prev.filter((filter) => filter !== value)
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <MetaDescTag
        title={"B2B | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="b2b-page">
        <div className="py-5 b2b-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 b2b-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">B2B</h1>
              </div>
              {/* {allProducts?.length > 0 ? ( */}
              <div className="b2b-page-body">
                <div className="filter-section">
                  <p className="mb-2">Categories</p>
                  {loadingFilters ? (
                    <Loading />
                  ) : (
                    <div className="filter-checkboxes">
                      {filterType.map((item, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item}
                            id={`flexCheckDefault-${index}`}
                            checked={selectedFilters.includes(item)}
                            onChange={handleFilterChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexCheckDefault-${index}`}
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <div className="d-grid gap-3 b2b-page-row">
                    {/* {loading ? (
                        <Loading />
                      ) : (
                        allProducts.map((product) => (
                          <BtoBProduct
                            key={product?.pkProdId}
                            product={product}
                          />
                        ))
                      )} */}
                    {loading ? (
                      <Loading />
                    ) : allProducts.length === 0 ? (
                      <p className="not-product-found">
                        No products found for the selected filters. Please
                        adjust your filters.
                      </p>
                    ) : (
                      allProducts.map((product) => (
                        <BtoBProduct
                          key={product?.pkProdId}
                          product={product}
                        />
                      ))
                    )}
                  </div>
                  <div className="mt-5 b2b-page-pagination">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`pagination-disable-btn ${
                        currentPage === 1 ? "prev-button-disabled" : ""
                      }`}
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      // disabled={currentPage === totalPages}
                      disabled={isLastPage}
                      className={`pagination-next-btn ${
                        currentPage === totalPages ? "next-button-disabled" : ""
                      }`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              {/* ) : (
                <p className="mb-0 text-center" style={{ fontSize: "40px" }}>
                  Products Not Found
                </p>
              )} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessToBusiness;
