import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ProductProvider from "./contexts/ProductContext";
import CartProvider from "./contexts/CartContext";
import BtoBProvider from "./contexts/BtoBContext";
import PersonalizedProvider from "./contexts/PersonalizedContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ProductProvider>
    <CartProvider>
      <BtoBProvider>
        <PersonalizedProvider>
        <App />
        </PersonalizedProvider>
      </BtoBProvider>
    </CartProvider>
  </ProductProvider>
);
