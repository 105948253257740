import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import MetaDescTag from "../../components/MetaDescTag";
import { imageGallery, videoGallery } from "../../data";
import "react-image-lightbox/style.css";
import Pagination from "../../components/Pagination";

const Gallery = () => {
  // State to store the selected category (either 'all', 'Solid Boxes', 'Floral Boxes', 'Celebration Boxes', 'Manufactory', or show all)
  const [selectedCategory, setSelectedCategory] = useState("all");

  // State to store the selected category (either 'boy', 'girl', or 'all')
  const [selectedVideoCategory, setSelectedVideoCategory] = useState("all");

  // State for lightbox
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  // Function to filter the images based on selected category
  const filteredImages = imageGallery.filter((item) =>
    selectedCategory === "all" ? true : item.imgCategory === selectedCategory
  );

  // Function to filter the videos based on selected category
  const filteredVideos = videoGallery.filter((item) =>
    selectedVideoCategory === "all"
      ? true
      : item.videoCategory === selectedVideoCategory
  );

  const totalPages = Math.ceil(filteredImages.length / itemsPerPage);

  // Get the images for the current page
  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Array of image URLs for the lightbox
  const images = filteredImages.map((item) => item.imgURL);

  // Handle image click to open the lightbox
  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Handle page change
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      {/* <MetaDescTag
        title={"Products | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      /> */}
      <section className="gallery-page">
        <div className="py-5 gallery-image-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 gallery-image-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">Images</h1>
              </div>
              <div className="gallery-image-page-body">
                <div className="gallery-image-filter-section">
                  <button
                    className={selectedCategory === "all" ? "active" : ""}
                    onClick={() => setSelectedCategory("all")}
                  >
                    All
                  </button>
                  <button
                    className={
                      selectedCategory === "Solid Boxes" ? "active" : ""
                    }
                    onClick={() => setSelectedCategory("Solid Boxes")}
                  >
                    Solid Boxes
                  </button>
                  <button
                    className={
                      selectedCategory === "Floral Boxes" ? "active" : ""
                    }
                    onClick={() => setSelectedCategory("Floral Boxes")}
                  >
                    Floral Boxes
                  </button>
                  <button
                    className={
                      selectedCategory === "Celebration Boxes" ? "active" : ""
                    }
                    onClick={() => setSelectedCategory("Celebration Boxes")}
                  >
                    Celebration Boxes
                  </button>
                  <button
                    className={
                      selectedCategory === "Manufactory" ? "active" : ""
                    }
                    onClick={() => setSelectedCategory("Manufactory")}
                  >
                    Manufactory
                  </button>
                </div>
                <div>
                  <div className="d-grid gap-3 gallery-image-page-row">
                  {paginatedImages.map((item, index) => (
                      <div
                        key={item.id}
                        className="text-decoration-none product-link d-flex justify-content-between flex-column p-2 gallery-image-row-block"
                      >
                        <img
                          src={item.imgURL}
                          alt={item.imgAltText}
                          onClick={() => openLightbox(index + (currentPage - 1) * itemsPerPage)} // Open lightbox on image click
                          style={{ cursor: "pointer" }} // Add cursor pointer to indicate it's clickable
                        />
                      </div>
                    ))}
                  </div>
                  {/* Pagination Component */}
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={onPageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="py-5 gallery-video-page-block">
          <div className="our-container">
            <div className="inner-container px-5 mx-auto">
              <div className="pb-5 gallery-video-page-header">
                <h1 className="fs-1 fw-medium text-capitalize mb-2">Videos</h1>
              </div>
              <div className="gallery-video-page-body">
                <div className="gallery-video-filter-section">
                  <button onClick={() => setSelectedVideoCategory("all")}>
                    All
                  </button>
                  <button onClick={() => setSelectedVideoCategory("boy")}>
                    Boy
                  </button>
                  <button onClick={() => setSelectedVideoCategory("girl")}>
                    Girl
                  </button>
                </div>
                <div className="w-100">
                  <div className="d-grid gap-3 gallery-video-page-row">
                    {filteredVideos.map((item) => (
                      <div
                        key={item.id}
                        className="text-decoration-none product-link d-flex justify-content-between flex-column p-2 gallery-image-row-block"
                      >
                        <iframe
                          height="286px"
                          width="100%"
                          src={item.videoURL}
                          title={`Video ${item.id}`}
                          frameBorder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Lightbox Component */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]} // Current image
            nextSrc={images[(photoIndex + 1) % images.length]} // Next image
            prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
            onCloseRequest={() => setIsOpen(false)} // Close the lightbox
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            } // Move to the previous image
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            } // Move to the next image
          />
        )}
      </section>
    </>
  );
};

export default Gallery;



















// import React, { useState } from "react";
// import Lightbox from "react-image-lightbox";
// import MetaDescTag from "../../components/MetaDescTag";
// import { imageGallery, videoGallery } from "../../data";
// import "react-image-lightbox/style.css";

// const Gallery = () => {
//   // State to store the selected category (either 'all', 'Solid Boxes', 'Floral Boxes', 'Celebration Boxes', 'Manufactory', or show all)
//   const [selectedCategory, setSelectedCategory] = useState("all");

//   // State to store the selected category (either 'boy', 'girl', or 'all')
//   const [selectedVideoCategory, setSelectedVideoCategory] = useState("all");

//   // State for lightbox
//   const [isOpen, setIsOpen] = useState(false);
//   const [photoIndex, setPhotoIndex] = useState(0);

//   // Function to filter the images based on selected category
//   const filteredImages = imageGallery.filter((item) =>
//     selectedCategory === "all" ? true : item.imgCategory === selectedCategory
//   );

//   // Function to filter the videos based on selected category
//   const filteredVideos = videoGallery.filter((item) =>
//     selectedVideoCategory === "all"
//       ? true
//       : item.videoCategory === selectedVideoCategory
//   );

//   // Array of image URLs for the lightbox
//   const images = filteredImages.map((item) => item.imgURL);

//   // Handle image click to open the lightbox
//   const openLightbox = (index) => {
//     setPhotoIndex(index);
//     setIsOpen(true);
//   };

//   return (
//     <>
//       {/* <MetaDescTag
//         title={"Products | Jewelry Boxes | KARV Accessories"}
//         description={
//           "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
//         }
//       /> */}
//       <section className="gallery-page">
//         <div className="py-5 gallery-image-page-block">
//           <div className="our-container">
//             <div className="inner-container px-5 mx-auto">
//               <div className="pb-5 gallery-image-page-header">
//                 <h1 className="fs-1 fw-medium text-capitalize mb-2">Images</h1>
//               </div>
//               <div className="gallery-image-page-body">
//                 <div className="gallery-image-filter-section">
//                   <button
//                     className={selectedCategory === "all" ? "active" : ""}
//                     onClick={() => setSelectedCategory("all")}
//                   >
//                     All
//                   </button>
//                   <button
//                     className={
//                       selectedCategory === "Solid Boxes" ? "active" : ""
//                     }
//                     onClick={() => setSelectedCategory("Solid Boxes")}
//                   >
//                     Solid Boxes
//                   </button>
//                   <button
//                     className={
//                       selectedCategory === "Floral Boxes" ? "active" : ""
//                     }
//                     onClick={() => setSelectedCategory("Floral Boxes")}
//                   >
//                     Floral Boxes
//                   </button>
//                   <button
//                     className={
//                       selectedCategory === "Celebration Boxes" ? "active" : ""
//                     }
//                     onClick={() => setSelectedCategory("Celebration Boxes")}
//                   >
//                     Celebration Boxes
//                   </button>
//                   <button
//                     className={
//                       selectedCategory === "Manufactory" ? "active" : ""
//                     }
//                     onClick={() => setSelectedCategory("Manufactory")}
//                   >
//                     Manufactory
//                   </button>
//                 </div>
//                 <div>
//                   <div className="d-grid gap-3 gallery-image-page-row">
//                     {filteredImages.map((item, index) => (
//                       <div
//                         key={item.id}
//                         className="text-decoration-none product-link d-flex justify-content-between flex-column p-2 gallery-image-row-block"
//                       >
//                         <img
//                           src={item.imgURL}
//                           alt={item.imgAltText}
//                           onClick={() => openLightbox(index)} // Open lightbox on image click
//                           style={{ cursor: "pointer" }} // Add cursor pointer to indicate it's clickable
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* <div className="py-5 gallery-video-page-block">
//           <div className="our-container">
//             <div className="inner-container px-5 mx-auto">
//               <div className="pb-5 gallery-video-page-header">
//                 <h1 className="fs-1 fw-medium text-capitalize mb-2">Videos</h1>
//               </div>
//               <div className="gallery-video-page-body">
//                 <div className="gallery-video-filter-section">
//                   <button onClick={() => setSelectedVideoCategory("all")}>
//                     All
//                   </button>
//                   <button onClick={() => setSelectedVideoCategory("boy")}>
//                     Boy
//                   </button>
//                   <button onClick={() => setSelectedVideoCategory("girl")}>
//                     Girl
//                   </button>
//                 </div>
//                 <div className="w-100">
//                   <div className="d-grid gap-3 gallery-video-page-row">
//                     {filteredVideos.map((item) => (
//                       <div
//                         key={item.id}
//                         className="text-decoration-none product-link d-flex justify-content-between flex-column p-2 gallery-image-row-block"
//                       >
//                         <iframe
//                           height="286px"
//                           width="100%"
//                           src={item.videoURL}
//                           title={`Video ${item.id}`}
//                           frameBorder="0"
//                           allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                           allowFullScreen
//                         ></iframe>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div> */}

//         {/* Lightbox Component */}
//         {isOpen && (
//           <Lightbox
//             mainSrc={images[photoIndex]} // Current image
//             nextSrc={images[(photoIndex + 1) % images.length]} // Next image
//             prevSrc={images[(photoIndex + images.length - 1) % images.length]} // Previous image
//             onCloseRequest={() => setIsOpen(false)} // Close the lightbox
//             onMovePrevRequest={() =>
//               setPhotoIndex((photoIndex + images.length - 1) % images.length)
//             } // Move to the previous image
//             onMoveNextRequest={() =>
//               setPhotoIndex((photoIndex + 1) % images.length)
//             } // Move to the next image
//           />
//         )}
//       </section>
//     </>
//   );
// };

// export default Gallery;
