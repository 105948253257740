import { IoDiamond } from "react-icons/io5";
import { FaRupeeSign } from "react-icons/fa";
import { GrCurrency } from "react-icons/gr";

export const zodiacData = [
  {
    sign: "Aries",
    dates: "March 21 - April 19",
    element: "Fire",
    rulingPlanet: "Mars",
    symbol: "Ram",
    personality: "Energetic, adventurous, courageous",
    imageUrl: "/images/zodiac/April-Aries.png",
    imageWhiteUrl: "/images/zodiac/April-Aries-White.png",
  },
  {
    sign: "Taurus",
    dates: "April 20 - May 20",
    element: "Earth",
    rulingPlanet: "Venus",
    symbol: "Bull",
    personality: "Patient, reliable, stubborn",
    imageUrl: "/images/zodiac/May-Taurus.png",
    imageWhiteUrl: "/images/zodiac/May-Taurus-White.png",
  },
  {
    sign: "Gemini",
    dates: "May 21 - June 20",
    element: "Air",
    rulingPlanet: "Mercury",
    symbol: "Twins",
    personality: "Adaptable, curious, inconsistent",
    imageUrl: "/images/zodiac/June-Gemini.png",
    imageWhiteUrl: "/images/zodiac/June-Gemini-White.png",
  },
  {
    sign: "Cancer",
    dates: "June 21 - July 22",
    element: "Water",
    rulingPlanet: "Moon",
    symbol: "Crab",
    personality: "Emotional, intuitive, protective",
    imageUrl: "/images/zodiac/July-Cancer.png",
    imageWhiteUrl: "/images/zodiac/July-Cancer-White.png",
  },
  {
    sign: "Leo",
    dates: "July 23 - August 22",
    element: "Fire",
    rulingPlanet: "Sun",
    symbol: "Lion",
    personality: "Dramatic, confident, generous",
    imageUrl: "/images/zodiac/August-Leo.png",
    imageWhiteUrl: "/images/zodiac/August-Leo-White.png",
  },
  {
    sign: "Virgo",
    dates: "August 23 - September 22",
    element: "Earth",
    rulingPlanet: "Mercury",
    symbol: "Maiden",
    personality: "Practical, analytical, critical",
    imageUrl: "/images/zodiac/September-Virgo.png",
    imageWhiteUrl: "/images/zodiac/September-Virgo-White.png",
  },
  {
    sign: "Libra",
    dates: "September 23 - October 22",
    element: "Air",
    rulingPlanet: "Venus",
    symbol: "Scales",
    personality: "Harmonious, diplomatic, indecisive",
    imageUrl: "/images/zodiac/October-Libra.png",
    imageWhiteUrl: "/images/zodiac/October-Libra-White.png",
  },
  {
    sign: "Scorpio",
    dates: "October 23 - November 21",
    element: "Water",
    rulingPlanet: "Pluto",
    symbol: "Scorpion",
    personality: "Passionate, secretive, determined",
    imageUrl: "/images/zodiac/November-Scorpio.png",
    imageWhiteUrl: "/images/zodiac/November-Scorpio-White.png",
  },
  {
    sign: "Sagittarius",
    dates: "November 22 - December 21",
    element: "Fire",
    rulingPlanet: "Jupiter",
    symbol: "Archer",
    personality: "Optimistic, adventurous, blunt",
    imageUrl: "/images/zodiac/December-Sagittarius.png",
    imageWhiteUrl: "/images/zodiac/December-Sagittarius-White.png",
  },
  {
    sign: "Capricorn",
    dates: "December 22 - January 19",
    element: "Earth",
    rulingPlanet: "Saturn",
    symbol: "Goat",
    personality: "Ambitious, disciplined, pessimistic",
    imageUrl: "/images/zodiac/January-Capricorn.png",
    imageWhiteUrl: "/images/zodiac/January-Capricorn-White.png",
  },
  {
    sign: "Aquarius",
    dates: "January 20 - February 18",
    element: "Air",
    rulingPlanet: "Uranus",
    symbol: "Water Bearer",
    personality: "Independent, progressive, unpredictable",
    imageUrl: "/images/zodiac/Fabruary-Aquarius.png",
    imageWhiteUrl: "/images/zodiac/Fabruary-Aquarius-White.png",
  },
  {
    sign: "Pisces",
    dates: "February 19 - March 20",
    element: "Water",
    rulingPlanet: "Neptune",
    symbol: "Fish",
    personality: "Compassionate, intuitive, escapist",
    imageUrl: "/images/zodiac/March-Pisces.png",
    imageWhiteUrl: "/images/zodiac/March-Pisces-White.png",
  },
];

export const footerNavLinks = [
  {
    id: 1,
    webUrl: "/",
    title: "Home",
  },
  {
    id: 2,
    webUrl: "/about-us",
    title: "About Us",
  },
  {
    id: 1,
    webUrl: "/product",
    title: "Product",
  },
  {
    id: 1,
    webUrl: "/contact-us",
    title: "Contact Us",
  },
];

export const aboutDetails = [
  {
    id: 1,
    imgUrl: "/images/img-gallery/about-us-01.jpg",
    altText:
      "Karv Accessories  Zipper Closer Faux Leather Jewellery Box - Small (Orange Teal)",
    mainTitle: "Elegant Storage",
    subTitle: "Crafted with Precision",
    paragraph:
      "Highlighting the beauty and sophistication of jewelry box designs.",
  },
  {
    id: 2,
    imgUrl: "/images/img-gallery/about-us-02.jpg",
    altText:
      "Karv Accessories  Zipper Closer Faux Leather Jewellery Box - Small (Pista)",
    mainTitle: "Organizational Bliss",
    subTitle: "Karv Accessories",
    paragraph:
      "Emphasizing how jewelry boxes keep your precious items organized and easily accessible.",
  },
  {
    id: 1,
    imgUrl: "/images/img-gallery/about-us-03.jpg",
    altText:
      "Karv Accessories  Zipper Closer Faux Leather Jewellery Box - Small (Pink)",
    mainTitle: "Personalized Touch",
    subTitle: "Experience Karv",
    paragraph:
      "Discussing customization options available for jewelry boxes to suit individual tastes and preferences.",
  },
];

export const aboutRefund = [
  {
    id: 1,
    icon: IoDiamond,
    title: "Return Policy",
    paragraph:
      "Love it or return it! We've got you covered with hassle-free returns.",
  },
  {
    id: 2,
    icon: FaRupeeSign,
    title: "Assured Savings",
    paragraph:
      "Rest assured, we guarantee you'll save on every purchase with our unbeatable prices.",
  },
  {
    id: 3,
    icon: GrCurrency,
    title: "Price Match Guaranteed",
    paragraph:
      "Shop with peace of mind knowing you're always getting the best deal with our price.",
  },
];

export const aboutWhoAreWe = [
  {
    id: 1,
    paragraph:
      "Welcome to KARV Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the visionary Karishma, our journey began with a mission to redefine leather goods and accessories.",
  },
  {
    id: 2,
    paragraph:
      "Started as a quest for unique, high-quality leather pieces has blossomed into Karv Accessories a brand synonymous with elegance, functionality, and unparalleled artistry.",
  },
  {
    id: 3,
    paragraph:
      "Each piece is meticulously crafted by skilled artisans who bring years of expertise to every stitch and detail. From our signature leather jewellery boxes, which blend timeless beauty with practical storage solutions, to our bespoke photo frames that elegantly frame your cherished memories, every Karv creation is a testament to our commitment to excellence.",
  },
];

export const faqData = [
  {
    id: 1,
    question: "What is KARV Accessories?",
    answer:
      "KARV Accessories is a brand that specializes in providing high-quality leather boxes, jewelry boxes, and accessories. Our products are designed to combine functionality with style, offering elegant storage solutions for your precious items.",
  },
  {
    id: 2,
    question: "Where are KARV Accessories products made?",
    answer:
      "All KARV Accessoriesproducts are crafted with care and precision, using high-quality materials. Our production facilities adhere to the highest standards of craftsmanship to ensure each product meets our quality criteria.",
  },
  {
    id: 3,
    question: "What types of leather are used in KARV Accessoriesproducts?",
    answer:
      "We use a variety of premium leathers, including full-grain and top-grain leathers, to ensure durability and a luxurious feel. Each type of leather is selected based on the specific requirements of the product.",
  },
  {
    id: 4,
    question: "How should I care for my leather box or accessory?",
    answer:
      "To maintain the quality and appearance of your leather products, avoid exposing them to direct sunlight and moisture. Use a soft, dry cloth to clean the surface regularly. For deeper cleaning, use a leather conditioner recommended for the specific type of leather.",
  },
  {
    id: 5,
    question: "Are the jewelry boxes lined to protect my items?",
    answer:
      "Yes, our jewelry boxes are lined with soft, protective materials to ensure your jewelry is kept safe from scratches and damage.",
  },
  {
    id: 6,
    question: "How can I place an order?",
    answer:
      "You can place an order directly through our website. Simply browse our products, add your selections to the cart, and proceed to checkout.",
  },
  {
    id: 7,
    question: "What payment methods do you accept?",
    answer:
      "We accept various payment methods, including credit/debit cards, PayPal, and other secure online payment options.",
  },
  {
    id: 8,
    question: "How long does shipping take?",
    answer:
      "Shipping times vary depending on your location. Typically, orders are processed within 2-3 business days, and standard shipping takes 5-7 business days within the country. International shipping times may vary.",
  },
  {
    id: 9,
    question: "Do you offer international shipping?",
    answer:
      "Yes, we offer international shipping to many countries. Shipping rates and times will be calculated at checkout based on your location.",
  },
  {
    id: 10,
    question: "What is your return policy?",
    answer:
      "We offer a 30-day return policy on all our products. If you are not satisfied with your purchase, you can return the item in its original condition for a refund or exchange. Please refer to our return policy on the website for detailed instructions.",
  },
  {
    id: 11,
    question: "How do I initiate a return or exchange?",
    answer:
      "To initiate a return or exchange, contact our customer service team via email or phone with your order details. They will guide you through the process and provide you with a return authorization.",
  },
  {
    id: 12,
    question: "Do you offer customization on your products?",
    answer:
      "Yes, we offer customization options on select products. You can choose from various colors, sizes, and personalization options. Contact our customer service for more details on customization.",
  },
  {
    id: 13,
    question: "Can I place a bulk order for corporate gifts or events?",
    answer:
      "Absolutely! We welcome bulk orders for corporate gifts, events, and special occasions. Contact our sales team with your requirements, and we will provide you with a tailored solution and pricing.",
  },
  {
    id: 14,
    question: "How can I contact KARV Accessories customer service?",
    answer:
      "You can reach our customer service team via email at mailto:support@karvccessories.com or by phone at [customer service phone number]. We are available Monday to Friday, 9 AM to 6 PM.",
  },
  {
    id: 15,
    question: "Do you have a physical store location?",
    answer:
      "Currently, KARV Accessories operates exclusively online to serve customers worldwide. However, we may have pop-up stores or events occasionally. Follow us on social media or sign up for our newsletter for updates.",
  },
];

export const homeClients = [
  "/images/clients/arihant.png",
  "/images/clients/bikanerwala.png",
  "/images/clients/dmart.png",
  "/images/clients/igp.png",
  "/images/clients/kbs_and_company.png",
  "/images/clients/prashant_corner.png",
  "/images/clients/oro-safe.png",
];

export const btobData = [
  {
    id: 1,
    title: "img 1",
    price: 399,
    imageUrl: "/images/web-images/about-1.jpg",
  },
  {
    id: 2,
    title: "img 2",
    price: 699,
    imageUrl: "/images/web-images/about-2.jpg",
  },
  {
    id: 3,
    title: "img 3",
    price: 399,
    imageUrl: "/images/web-images/about-3.jpg",
  },
  {
    id: 4,
    title: "img 4",
    price: 499,
    imageUrl: "/images/web-images/left-img.jpg",
  },
  {
    id: 5,
    title: "img 5",
    price: 599,
    imageUrl: "/images/web-images/right-img.jpg",
  },
  {
    id: 6,
    title: "img 6",
    price: 699,
    imageUrl: "/images/web-images/about-1.jpg",
  },
];

export const sliderData = [
  // {
  //   id: 1,
  //   image: "./images/web-images/Karv-Diwali-Banner-01.jpg",
  //   altText: "Homepage Festival Banner",
  // },
  {
    id: 2,
    image: "./images/web-images/Karv-Ganpati-Banner-Carousel-02.jpg",
    altText: "Homepage Personalized Banner KARV Accessories",
  },
  {
    id: 3,
    image: "./images/web-images/Karv-Ganpati-Banner-Carousel-03.jpg",
    altText: "Homepage Non Personalized Banner KARV Accessories",
  },
];

export const imageGallery = [
  {
    id: 1,
    imgURL: "./images/gallery/img-gallery/Karv-Black-Plain-Large-01.jpg",
    imgAltText: "",
    imgCategory: "Solid Boxes",
  },
  {
    id: 2,
    imgURL: "./images/gallery/img-gallery/Karv-Large-no-Patti-Peach-01.jpg",
    imgAltText: "",
    imgCategory: "Floral Boxes",
  },
  {
    id: 3,
    imgURL: "./images/gallery/img-gallery/Karv-Small-Pink-01.jpg",
    imgAltText: "",
    imgCategory: "Floral Boxes",
  },
  {
    id: 4,
    imgURL: "./images/gallery/img-gallery/Karv-Cream-Large-01.jpg",
    imgAltText: "",
    imgCategory: "Solid Boxes",
  },
  {
    id: 5,
    imgURL: "./images/gallery/img-gallery/Karv-Small-Green-locable-01.jpg",
    imgAltText: "",
    imgCategory: "Celebration Boxes",
  },
  {
    id: 6,
    imgURL: "./images/gallery/img-gallery/Karv-Small-Pink-01.jpg",
    imgAltText: "",
    imgCategory: "Floral Boxes",
  },
  {
    id: 7,
    imgURL: "./images/gallery/img-gallery/Karv-Large-no-Patti-Pink-01.jpg",
    imgAltText: "",
    imgCategory: "Floral Boxes",
  },
  {
    id: 8,
    imgURL: "./images/gallery/img-gallery/Karv-Small-Blue-locable-01.jpg",
    imgAltText: "",
    imgCategory: "Celebration Boxes",
  },
  {
    id: 9,
    imgURL: "./images/gallery/img-gallery/Karv-Orange-Teal-Large-01.jpg",
    imgAltText: "",
    imgCategory: "Solid Boxes",
  },
  {
    id: 10,
    imgURL: "./images/gallery/img-gallery/Karv-Large-no-Patti-Pista-01.jpg",
    imgAltText: "",
    imgCategory: "Floral Boxes",
  },
  {
    id: 11,
    imgURL: "./images/gallery/img-gallery/Karv-Pista-Plain-Large-01.jpg",
    imgAltText: "",
    imgCategory: "Solid Boxes",
  },
  {
    id: 12,
    imgURL: "./images/gallery/img-gallery/Karv-Light-Brown-Large-01.jpg",
    imgAltText: "",
    imgCategory: "Solid Boxes",
  },
  {
    id: 13,
    imgURL: "./images/gallery/img-gallery/Karv-Large-no-Patti-Purple-01.jpg",
    imgAltText: "",
    imgCategory: "Floral Boxes",
  },
  {
    id: 14,
    imgURL: "./images/gallery/img-gallery/Karv-Small-Pink-locable-01.jpg",
    imgAltText: "",
    imgCategory: "Celebration Boxes",
  },
  {
    id: 15,
    imgURL: "./images/gallery/img-gallery/Karv-Small-Purple-01.jpg",
    imgAltText: "",
    imgCategory: "Floral Boxes",
  },
  {
    id: 16,
    imgURL: "./images/gallery/img-gallery/manufactory-01.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 17,
    imgURL: "./images/gallery/img-gallery/manufactory-02.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 18,
    imgURL: "./images/gallery/img-gallery/manufactory-03.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 19,
    imgURL: "./images/gallery/img-gallery/manufactory-04.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 20,
    imgURL: "./images/gallery/img-gallery/manufactory-05.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 21,
    imgURL: "./images/gallery/img-gallery/manufactory-06.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 22,
    imgURL: "./images/gallery/img-gallery/manufactory-07.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 23,
    imgURL: "./images/gallery/img-gallery/manufactory-08.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 24,
    imgURL: "./images/gallery/img-gallery/manufactory-09.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
  {
    id: 25,
    imgURL: "./images/gallery/img-gallery/manufactory-10.jpg",
    imgAltText: "",
    imgCategory: "Manufactory",
  },
];

export const videoGallery = [
  {
    id: 1,
    videoURL: "https://www.youtube.com/embed/OgLyfumDGtM?rel=0",
    videoCategory: "boy",
  },
  {
    id: 2,
    videoURL: "https://www.youtube.com/embed/OgLyfumDGtM?rel=0",
    videoCategory: "girl",
  },
  {
    id: 3,
    videoURL: "https://www.youtube.com/embed/OgLyfumDGtM?rel=0",
    videoCategory: "girl",
  },
  {
    id: 4,
    videoURL: "https://www.youtube.com/embed/OgLyfumDGtM?rel=0",
    videoCategory: "girl",
  },
  {
    id: 5,
    videoURL: "./images/gallery/video-gallery/exhibition-02.mp4",
    videoCategory: "boy",
  },
  {
    id: 6,
    videoURL: "https://www.youtube.com/embed/OgLyfumDGtM?rel=0",
    videoCategory: "girl",
  },
  {
    id: 7,
    videoURL: "https://www.youtube.com/embed/OgLyfumDGtM?rel=0",
    videoCategory: "girl",
  },
  {
    id: 8,
    videoURL: "https://www.youtube.com/embed/OgLyfumDGtM?rel=0",
    videoCategory: "boy",
  },
];

export const blogs = [
  {
    id: 1,
    slug: "what-is-search-engine-optimization",
    blogTitle:
      "What Is Search Engine Optimization (SEO) , Evolution And Its Benefits ?",
    blogDesc:
      "Search Engine Optimization (SEO) is the process of optimizing a website to improve its visibility and ranking on search engines like Google, Bing, and Yahoo. By optimizing various elements of a website—such as content, keywords, meta tags, and backlinks—SEO helps websites appear higher in search engine results pages (SERPs) for relevant search queries. The ultimate goal of SEO is to attract more organic (non-paid) traffic to a website, leading to increased visibility, credibility, and conversions. The higher your website ranks, the more likely people are to find you when searching for products or services related to your business.",
    blogImg: "/images/gallery/img-gallery/img-12-03.jpg",
  },
  {
    id: 1,
    slug: "hey-how-are-you",
    blogTitle: "Hey, How are you ?",
    blogDesc:
      "Search Engine Optimization (SEO) is the process of optimizing a website to improve its visibility and ranking on search engines like Google, Bing, and Yahoo. By optimizing various elements of a website—such as content, keywords, meta tags, and backlinks—SEO helps websites appear higher in search engine results pages (SERPs) for relevant search queries. The ultimate goal of SEO is to attract more organic (non-paid) traffic to a website, leading to increased visibility, credibility, and conversions. The higher your website ranks, the more likely people are to find you when searching for products or services related to your business.",
    blogImg: "/images/gallery/img-gallery/img-12-03.jpg",
  },
];

export const blogDetail = [
  {
    id: 1,
    slug: "what-is-search-engine-optimization",
    blogTitle:
      "What Is Search Engine Optimization (SEO) , Evolution And Its Benefits ?",
    blogDesc:
      "Search Engine Optimization (SEO) is the process of optimizing a website to improve its visibility and ranking on search engines like Google, Bing, and Yahoo. By optimizing various elements of a website—such as content, keywords, meta tags, and backlinks—SEO helps websites appear higher in search engine results pages (SERPs) for relevant search queries. The ultimate goal of SEO is to attract more organic (non-paid) traffic to a website, leading to increased visibility, credibility, and conversions. The higher your website ranks, the more likely people are to find you when searching for products or services related to your business.",
    blogImg: "./images/gallery/img-gallery/img-12-03.jpg",
    data: "Some key milestones in the evolution of SEO include:",
    list: [
      "Mid-1990s: The advent of search engines led website owners to start optimizing their content to rank higher in search results. Early techniques included keyword stuffing and using meta tags.",
      '1996: Larry Page and Sergey Brin, while working on their Ph.D. at Stanford University, developed a search engine called "BackRub," which later became Google. Their innovative approach of ranking pages based on the number and quality of backlinks revolutionized how search engines worked.',
      "1998: Google was officially founded, and its PageRank algorithm became the standard for how search engines ranked websites. This marked the beginning of more sophisticated SEO practices, focusing on link-building, content quality, and relevance.",
    ],
    subPara:
      "SEO is a vital digital marketing strategy that helps businesses attract more visitors, improve credibility, and achieve higher conversion rates by optimizing their online presence.",
  },
  {
    id: 1,
    slug: "hey-how-are-you",
    blogTitle: "Hey, How are you ?",
    blogDesc:
      "Search Engine Optimization (SEO) is the process of optimizing a website to improve its visibility and ranking on search engines like Google, Bing, and Yahoo. By optimizing various elements of a website—such as content, keywords, meta tags, and backlinks—SEO helps websites appear higher in search engine results pages (SERPs) for relevant search queries. The ultimate goal of SEO is to attract more organic (non-paid) traffic to a website, leading to increased visibility, credibility, and conversions. The higher your website ranks, the more likely people are to find you when searching for products or services related to your business.",
    blogImg: "./images/gallery/img-gallery/img-12-03.jpg",
  },
];
