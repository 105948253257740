import React, { createContext, useEffect, useState } from "react";

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("currentPage")) || 1
  );
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(localStorage.getItem("selectedFilters")) || []
  );
  const [isZipper, setIsZipper] = useState(
    JSON.parse(localStorage.getItem("isZipper")) || ""
  );
  const [isLockable, setIsLockable] = useState(
    JSON.parse(localStorage.getItem("isLockable")) || ""
  );
  const [categoryFilter, setCategoryFilter] = useState(
    JSON.parse(localStorage.getItem("categoryFilter")) || []
  );
  const [sizeFilter, setSizeFilter] = useState([]);
  const [materialFilter, setMaterialFilter] = useState("");
  const [priceRange, setPriceRange] = useState([0, 1000]);

  // // Fetch products based on page and filters
  // const fetchProducts = async (pageNo, pageSize = 10, filters) => {
  //   try {
  //     const category = filters.length > 0 ? filters.join(",") : categoryFilter.join(",");
  //     const sizes = sizeFilter.length > 0 ? sizeFilter.join(",") : "";
  //     const material = materialFilter ? materialFilter : "";
  
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/product/search`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           pkProdId: "",
  //           prodName: "",
  //           prodCategory: category || "",
  //           prodType: "",
  //           price: "",
  //           isActive: "1",
  //           pageNo: pageNo,
  //           pageSize: pageSize,
  //           isZipper: isZipper || "",
  //           isLockable: isLockable || "",
  //           priceFrom: priceRange[0],
  //           priceTo: priceRange[1],
  //           prodSize: sizes,
  //           prodMaterial: material,
  //         }),
  //       }
  //     );
  
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch products");
  //     }
  
  //     const data = await response.json();
  
  //     if (data.totalResult === 0) {
  //       setAllProducts([]);
  //     } else {
  //       const totalResults = data.totalResult;
  //       const totalPages = Math.ceil(totalResults / pageSize);
  //       const productsWithSizes = data?.masterData?.map((product) => ({
  //         ...product,
  //         info: product?.info?.map((item) => ({
  //           ...item,
  //         })),
  //       }));
  
  //       setTotalProducts(totalResults);
  //       setTotalPages(totalPages);
  //       return productsWithSizes;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //     return [];
  //   }
  // };

  // Fetch all products
  const fetchProducts = async (pageNo, pageSize = 10, filters) => {
    try {
      const category = filters.length > 0 ? filters : categoryFilter.length > 0 ? categoryFilter : null;
      const sizes = sizeFilter.length > 0 ? sizeFilter : null;
      const material = materialFilter ? [materialFilter] : null; // Convert material to an array if set
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: "",
            prodName: "",
            prodCategory: category,
            prodType: "",
            price: "",
            isActive: "1",
            pageNo: pageNo,
            pageSize: pageSize,
            isZipper: isZipper || "",
            isLockable: isLockable || "",
            priceFrom: priceRange[0],
            priceTo: priceRange[1],
            prodSize: sizes,
            prodMaterial: material,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
  
      const data = await response.json();
      if (data.totalResult === 0) {
        setAllProducts([]);
      } else {
        const totalResults = data.totalResult;
        const totalPages = Math.ceil(totalResults / pageSize);
        const productsWithSizes = data?.masterData?.map((product) => ({
          ...product,
          info: product?.info?.map((item) => ({
            ...item,
          })),
        }));
  
        setTotalProducts(totalResults);
        setTotalPages(totalPages);
        return productsWithSizes;
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };
  
  const fetchAllProducts = async () => {
    setLoading(true);
    try {
      const products = await fetchProducts(
        currentPage,
        postsPerPage,
        selectedFilters
      );
      if (products.length === 0 && currentPage > 1) {
        setCurrentPage(1);
      } else {
        setAllProducts(products.slice(0, 10));
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all products when the component first mounts or on page refresh
  useEffect(() => {
    fetchAllProducts();
  }, [currentPage, selectedFilters, isZipper, isLockable, categoryFilter, priceRange,sizeFilter,materialFilter]);

  // Persist state changes to localStorage
  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
    localStorage.setItem("isZipper", JSON.stringify(isZipper));
    localStorage.setItem("isLockable", JSON.stringify(isLockable));
    localStorage.setItem("categoryFilter", JSON.stringify(categoryFilter));
    localStorage.setItem("sizeFilter", JSON.stringify(sizeFilter));
    localStorage.setItem("materialFilter", materialFilter);
  }, [currentPage, selectedFilters, isZipper, isLockable, categoryFilter,sizeFilter,materialFilter]);

  const handleSizeFilterChange = (sizes) => {
    setSizeFilter(sizes);
  };

  const handleMaterialFilterChange = (material) => {
    setMaterialFilter(material);
  };

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  const handleZipperChange = (checked) => {
    setIsZipper(checked ? "1" : "");
  };

  const handleLockableChange = (checked) => {
    setIsLockable(checked ? "1" : "");
  };

  const handleCategoryFilterChange = (categories) => {
    setCategoryFilter(categories);
  };

  // Disable "Next" button if it's the last page
  const isLastPage = currentPage * 10 >= totalProducts;

  const getProductDetailsById = async (productId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: productId,
            prodName: "",
            prodCategory: null,
            prodType: "",
            price: "",
            pageNo: "",
            pageSize: "",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }
      const product = await response.json();
      return product;
    } catch (error) {
      console.error("Error fetching product details:", error);
      return null;
    }
  };

  const getSuggestedProducts = (productId) => {
    const product = getProductDetailsById(productId);
    return product ? product.suggestedProducts || [] : [];
  };

  return (
    <ProductContext.Provider
      value={{
        allProducts,
        getProductDetailsById,
        getSuggestedProducts,
        fetchAllProducts,
        currentPage,
        setCurrentPage,
        postsPerPage,
        totalPages,
        loading,
        setLoading,
        setPostsPerPage,
        selectedFilters,
        setSelectedFilters: handleFilterChange,
        isZipper,
        setIsZipper: handleZipperChange,
        isLockable,
        setIsLockable: handleLockableChange,
        sizeFilter,
        setSizeFilter: handleSizeFilterChange,
        materialFilter,
        setMaterialFilter: handleMaterialFilterChange,
        categoryFilter,
        setCategoryFilter: handleCategoryFilterChange,
        priceRange,
        setPriceRange,
        isLastPage,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
