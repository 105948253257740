import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { aboutDetails, aboutRefund, aboutWhoAreWe } from "../../data";
import MetaDescTag from "../../components/MetaDescTag";

const AboutUs = () => {
  const videoRef = useRef(null);

  // Programmatically play the video when the component loads
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Autoplay started successfully
          })
          .catch((error) => {
            // Autoplay failed (possibly due to browser restrictions)
            console.log("Autoplay failed:", error);
          });
      }
    }
  }, []);

  return (
    <>
      <MetaDescTag
        title={"About Us | Jewelry Boxes | KARV Accessories"}
        description={
          "Welcome to Karv Accessories, where passion, craftsmanship, and style unite to create exquisite leather products. Founded by the dynamic duo, Karishma and Vikram, our journey began with a shared vision to redefine leather goods and accessories."
        }
      />
      <section className="about-page">
        <div className="about-page-detail-block py-5">
          <div className="our-container">
            <div className="inner-container mx-auto px-5">
              <div className="row about-page-detail-body mx-0">
                <div className="col apd-block">
                  <div className="row gap-4 h-100 apd-row">
                    {aboutDetails.map((item, index) => {
                      return (
                        <div
                          className="col position-relative p-0 apd-block-sub"
                          key={index}
                        >
                          <img src={item.imgUrl} alt={item.altText} />
                          <div className="position-absolute apd-img-content">
                            <div className="h4 text-uppercase mb-2">
                              {item.mainTitle}
                            </div>
                            <div className="h6 fw-bold text-uppercase mb-2">
                              {item.subTitle}
                            </div>
                            <span className="apd-center-underline"></span>
                            <p className="mb-3">{item.paragraph}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-page-our-details">
          <div className="our-container">
            <div className="inner-container">
              <div className="about-page-our-details-header">
                <h1>Who are we?</h1>
              </div>
              <div className="about-page-our-details-body">
                <div className="about-page-our-details-para">
                  {aboutWhoAreWe.map((item, index) => {
                    return <p key={index}>{item.paragraph}</p>;
                  })}
                </div>
                <video
                  ref={videoRef}
                  className="banner-video py-5"
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src="./Karv-About-Video-01.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="about-page-our-details-my-services">
                  <div className="inner-container">
                    <div className="about-page-our-details-my-services-row">
                      {aboutRefund.map((item, index) => {
                        const IconComponent = item.icon;
                        return (
                          <div
                            className="about-page-our-details-my-services-icon-block"
                            key={index}
                          >
                            <IconComponent className="services-icon" />
                            <div className="h3">{item.title}</div>
                            <p>{item.paragraph}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
