import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'rc-slider/assets/index.css';
import "./styles/app.scss";
import "./styles/media_query.scss";

// components
import Header from "./components/Header";
import Footer from "./components/Footer";

// pages
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import SingleProduct from "./pages/SingleProduct";
import BusinessToBusiness from "./pages/BusinessToBusiness";
import ContactUs from "./pages/ContactUs";
import Cart from "./pages/Cart";
import CheckOut from "./pages/CheckOut";
import NotFound from "./pages/NotFound";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThankYou from "./pages/ThankYou";
import FAQs from "./pages/FAQs";
import Personalized from "./pages/Personalized";
import Blog from "./pages/Blog";
import Gallery from "./pages/Gallery";
import BlogDetail from "./pages/BlogDetail";

// any link to click than render to top
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function App() {
  useEffect(() => {
    console.log("App loaded or refreshed");
  }, []);

  return (
    <>
      <div className="app">
        <ToastContainer />
        <BrowserRouter>
          <ScrollToTop />
          <Header />
          <div className="app-body">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/personalized" element={<Personalized />} />
              <Route path="/product" element={<Products />} />
              <Route path="/product/:id" element={<SingleProduct />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/b2b" element={<BusinessToBusiness />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<CheckOut />} />
              {/* <Route path="/thank-you" element={<ThankYou />} /> */}
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/faq" element={<FAQs />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="/gallery" element={<Gallery />} />
              {/* <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/blog/:slug" element={<BlogDetail />} /> */}
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
