import React, { createContext, useEffect, useState } from "react";

export const BtoBContext = createContext();

const BtoBProvider = ({ children }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0); // Track total products
  const [loading, setLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(localStorage.getItem("selectedFilters")) || []
  );
  const [categoryFilter, setCategoryFilter] = useState(
    JSON.parse(localStorage.getItem("categoryFilter")) || []
  );

  const fetchProducts = async (pageNo, pageSize = 12, filters) => {
    const category = filters.length > 0 ? filters : categoryFilter.length > 0 ? categoryFilter : null;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/product/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkProdId: "",
            prodName: "",
            prodCategory: category,
            prodType: "",
            price: "",
            isActive: "1",
            isB2B: "1",
            pageNo: pageNo,
            pageSize: pageSize,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      const totalResults = data.totalResult;
      const totalPages = Math.ceil(totalResults / pageSize);
      setTotalProducts(data.totalResult);

      const productsWithSizes = data?.masterData?.map((product) => ({
        ...product,
        info: product?.info?.map((item) => ({
          ...item,
        })),
      }));
      setTotalPages(totalPages);
      return productsWithSizes;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchAllProducts = async () => {
      setLoading(true);
      try {
        const products = await fetchProducts(
          currentPage,
          postsPerPage,
          selectedFilters
        );
        setAllProducts(products.slice(0, 12));
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllProducts();
  }, [currentPage, postsPerPage, selectedFilters]);

  // Persist state changes to localStorage
  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
  }, [currentPage, selectedFilters]);

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  // Disable "Next" button if it's the last page
  const isLastPage = currentPage * 12 >= totalProducts;

  return (
    <BtoBContext.Provider
      value={{
        allProducts,
        currentPage,
        setCurrentPage,
        postsPerPage,
        totalPages,
        loading,
        setLoading,
        setPostsPerPage,
        selectedFilters,
        setSelectedFilters: handleFilterChange,
        isLastPage,
      }}
    >
      {children}
    </BtoBContext.Provider>
  );
};

export default BtoBProvider;
