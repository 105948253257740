import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ImageGallery from "../../components/ImageGallery";
import { homeClients, sliderData } from "../../data";
import MetaDescTag from "../../components/MetaDescTag";
import SlickCarousel from "../../components/SlickCarousel";

const Home = () => {
  // website design and developed info
  const websiteDevelopedBy = localStorage.setItem(
    "websiteDevelopedBy",
    process.env.REACT_APP_OWNER_FOR_WEBSITE_DEVELOPMENT
  );
  // const backendDevelopedBy = localStorage.setItem(
  //   "backendDevelopedBy",
  //   process.env.REACT_APP_OWNER_FOR_BACKEND_DEVELOPMENT
  // );

  const videoRef = useRef(null);

  // Programmatically play the video when the component loads
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Autoplay started successfully
          })
          .catch((error) => {
            // Autoplay failed (possibly due to browser restrictions)
            console.log("Autoplay failed:", error);
          });
      }
    }
  }, []);

  const [hoveredBgColor, setHoveredBgColor] = useState("");

  return (
    <>
      <MetaDescTag
        title={"KARV Accessories"}
        description={
          "Unveil the allure of KARV's latest collection, featuring an exquisite range of jewelry boxes, premium leather goods, and refined accessories. Our new line captures the essence of contemporary luxury with a perfect blend of classic design and innovative craftsmanship."
        }
      />
      <section className="home-page">
        <div className="main-banner-block overflow-hidden">
          <div className="inner-container p-0">
            <SlickCarousel sliderData={sliderData} />
          </div>
        </div>
        <div
          className="pt-4 pb-5 shop-your-product"
          style={{ backgroundColor: hoveredBgColor }}
        >
          <div className="our-container">
            <div className="inner-container mx-auto px-5">
              <div className="row shop-product-body mx-0">
                <div className="col spb-right-block">
                  <div className="row gap-4 justify-content-between h-100 spb-row">
                    <Link
                      to={"/personalized"}
                      className="col position-relative p-0 spb-left-block"
                      onMouseEnter={() => setHoveredBgColor("#E7E1D1")}
                      onMouseLeave={() => setHoveredBgColor("")}
                    >
                      <img
                        src={
                          "./images/web-images/Karv-Customize-Sticker-Banner-left.jpg"
                        }
                        alt="Homepage Zodiac Banner Customise Jewelry Boxes With you name and zodiac flower sign KARV Accessories"
                      />
                    </Link>
                    <Link
                      to={"/b2b"}
                      className="col position-relative p-0 spb-right-block"
                      onMouseEnter={() => setHoveredBgColor("#D8F0F0")}
                      onMouseLeave={() => setHoveredBgColor("")}
                    >
                      <img
                        src={
                          "./images/web-images/Karv-Customize-Sticker-Banner-Rightleft.jpg"
                        }
                        alt="Homepage B2B Banner Buy In Bulk And Save Big KARV Accessories"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/personalized" className="main-banner-video">
          <video
            ref={videoRef}
            className="banner-video"
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src="./Karv-Customized-Banner-Video-01.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Link>
        <ImageGallery />
        <div className="pb-5 client-logo">
          <div className="inner-container">
            <div className="client-logo-header">
              <div className="h3 mt-2 mb-0">Our Clients</div>
            </div>
            <div className="client-logo-body">
              <div className="client-logo-row">
                {homeClients.map((item, index) => {
                  return (
                    <div key={index} className="client-logo-block">
                      <img src={item} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
